.App {
  text-align: center;
  background-color: #8ba6c1;
  color: #ffffff;
  margin:0;
  padding:0;
}
html, body{
  background-color: #8ba6c1;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.SimpleReader {
  position: absolute;
  top:0;
  left:0;
  display: block;
  height: 100vh;
  width: 100vw;
  margin:0;
  padding:0;
  color: white;
  margin: 0;
  padding: 0;
  background-color: #8ba6c1;
}
.Header h1 {
  position: absolute;
  left: 0.5em;
  top: -1em;
  font-size: 2em;
  color: #fff;
  text-align: center;
  margin: 0;
  padding: 0;
  margin-top: 1em;
  float: left;
  font-family: 'Times New Roman', Times, serif;
}
.ReaderViewer{
  width:min(100vw, 100vh);
  background-color: #fff;
  box-sizing: border-box;
  resize: horizontal;
  border: none;
  overflow: auto;
  height: min(100vw, 100vh);
  position: absolute;
  top: calc(50vh - min(50vw, 50vh));
  left: calc(50vw - min(50vw, 50vh));
}
.PictureButton{
  background: #ff0000;
  text-align: center;
  width: 2em;
  height: 2em;
  border-radius: 100%;border-color: #ffffff;
  border-width: 0.1em;
  display: inline-block;
  font-size: 2em;
  line-height: 2.3;
  vertical-align:middle;
  color: white;
  font-weight: bold;
  text-decoration: none;
  position: absolute;
  bottom:3em;
  left: 50%;
  margin-left: -1em;
}
.LoginHeader{

  font-family: 'Times New Roman', Times, serif;
  font-size: 4em;
  color: white;
  text-shadow: 2px 2px 4px #000000;
  text-align: center;
  
}
.LoginFooter{
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3.5em;
  width: 100vw;
  z-index: 100;
  padding: 0.5em;
  background-color: rgba(255, 255,255, 0.3);
  
}
.Header{
  text-align: right;
  font-size: 1em;
  color: #000;
  top: -4em;
  position: absolute;
  top: 0;
  left: 0;
  height: 3.5em;
  width: 100vw;
  z-index: 100;
  padding: 0.5em;
  background-color: rgba(255, 255,255, 0.3);
}
.Header button{
  background-color: #ffffff;
  border-radius: 0.5em;
  color: #000000;
  border: none;
  padding: 0.5em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
}
.ResultScreen{
  display: block;
  width: 100vw;
  color: white;
  margin: 0;
  padding: 0;
  background-color: #8ba6c1;
}
.ResultScreen img{
  margin: 0;
  padding: 0;
  margin-top:3.5em;
  width: 100%;
  height: 100%;
  object-fit: contain;

}
.ResultStatus{
  text-align: center;
  font-size: 2em;
  color: #000;
  top: -4em;
  position: relative;
}
.ResultImage{
  margin: 0;
  padding: 0;
  position: relative;
  display: block;
  width: 100vw;
  height: 100vw;
  color: white;
  margin: 0;
  padding: 0;
  background-color: #000;
}
.ResultSign{
  position: absolute;
  border: 1px dotted #8ba6c1;
  display: block;
  align-items: center;
  justify-content: center;
  z-index: 100;
  text-align: left;
  border-radius: 0.5em;
  box-shadow: #eeeeee 0 0 0.5em;
}
.ResultSign span{
  font-size: 1em;
  color: #1d2329;
  font-weight: bold;
  text-align: center;
  box-shadow: #eeeeee 0 0 0.5em;
  padding-right:2px;
  width: 100%;
  opacity: 0.5;
}
.ResultText{
  color: #fff;
  display: block;
  padding: 0.8em;
  white-space: pre-line;
}
.ResultText span{
  padding: 0.5em;
  font-size: 1.5em;
  color: #fff;
  
}
.ResultScreen button{
  background-color: #ffffff;
  color: #8ba6c1;
  border: none;
  padding: 1em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  margin: 0.5em;
  cursor: pointer;
  border-radius: 0.5em;
  margin-bottom: 1em;
  left: 50%;
  position: relative;
  width: 8em;
  margin-left: -4em;
}
.lds-grid {
  display: inline-block;
  position: absolute;
  width: 80em;
  height: 80em;
  font-size: 0.3em;
  top: 0px;
  z-index: 60;
}
.lds-grid div {
  position: absolute;
  width: 16em;
  height: 16em;
  border-radius: 50%;
  background: #fff;
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 8em;
  left: 8em;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 8em;
  left: 32em;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 8em;
  left: 56em;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 32em;
  left: 8em;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 32em;
  left: 32em;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 32em;
  left: 56em;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 56em;
  left: 8em;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 56em;
  left: 32em;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 56em;
  left: 56em;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80em;
  height: 80em;
  left: 50%;
  margin-left: -40em;
  font-size: 0.15em;
  top: 0px;
  z-index: 60;
}
.lds-ripple div {
  position: absolute;
  border: 4em solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
.QuestionForm{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 40em;
  background-color: #ffffff;
  padding: 2em;
  border-radius: 0.5em;
  box-shadow: 0 0 1em #000000;
  z-index: 100;
}
.QuestionForm textarea{
  width: 100%;
  height: 10em;
  border: 1px solid #000000;
  border-radius: 0.5em;
  padding: 0.5em;
  margin: 0.5em 0;
  box-sizing: border-box;
}
.QuestionForm button{
  background-color: #ffffff;
  color: #8ba6c1;
  border: none;
  padding: 1em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  margin: 0.5em;
  cursor: pointer;
  border-radius: 0.5em;
  margin-bottom: 1em;
  left: 50%;
  position: relative;
  width: 8em;
  margin-left: -4em;
}
@keyframes lds-ripple {
  0% {
    top: 36em;
    left: 36em;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36em;
    left: 36em;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36em;
    left: 36em;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72em;
    height: 72em;
    opacity: 0;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

[data-amplify-authenticator][data-variation=modal]{
  background-image: url("../public/img/bg_trees.png");
  background-size: cover;
}